import React, { Component } from "react";
// import logo from "./logo.svg";
import "./404.css";
import { Link } from "react-router-dom";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <main className="containerfor404">
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">4</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <span className="particle">0</span>
        <article className="content">
          <p>
            <strong>404</strong>
          </p>
          <p>Stop being sneaky!</p>
          <p>
            <Link to="/">Go back to THRWorks.</Link>
          </p>
        </article>
      </main>
    );
  }
}

export default NotFound;
