import React from "react";
import { Route, Routes } from "react-router-dom";
import Message from "./pages/Message";
import NewHome from "./pages/NewHome";
import NotFound from "./pages/404";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<NewHome />} />
      <Route path="/message/:id" element={<Message />} />
      {/* <Route exact path="/2" element={<Home/>} /> */}
      {/* when none of the above match, <NoMatch> will be rendered */}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
