import React, { useState } from "react";
import Menu from "./Menu";
import "./Header.scss";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "About Me",
  });
  // State of our button
  const [disabled, setDisabled] = useState(false);
  let navigate = useNavigate();

  // //Use Effect
  // useEffect(() => {
  //   //Listening for page changes.
  //   // history.listen(() => {
  //   setState({ clicked: false, menuName: "About Me" });
  //   // });
  // }, []);

  // Toggle menu
  const handleMenu = () => {
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "Close",
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "About Me",
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "Close",
      });
    }
  };

  return (
    <header>
      <div className="container">
        <div className="wrapper">
          <div className="inner-header">
            <div className="logo">
              <h2
                onClick={() => {
                  navigate("/");
                }}
                style={{ cursor: "pointer" }}
              >
                ThrWorks
              </h2>
            </div>
            <div className="menu">
              <button onClick={handleMenu}>{state.menuName}</button>
            </div>
          </div>
        </div>
      </div>
      <Menu state={state} />
    </header>
  );
};

export default Header;
