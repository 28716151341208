import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "./config/theme";
import AOS from "aos";
import "aos/dist/aos.css";
import Inspect from "inspx";
import { logEvent } from "firebase/analytics";
import { analytics } from "config/fb";

function App() {
  useEffect(() => {
    logEvent(analytics, "page_view");
    AOS.init({
      offset: 0,
      easing: "ease-in-out-cubic",
      duration: 800,
    });
  }, []);

  let lang;
  const isRTL = lang === "en";
  const theme = createTheme(isRTL);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Inspect>
          <Router />
        </Inspect>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
