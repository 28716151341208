import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { TransformOutlined } from "@material-ui/icons";

import imagebg from "assets/images/mountain.jpg";
import about from "assets/images/about.jpg";
import mumbai from "assets/images/mumbai.jpg";
import riyadh from "assets/images/riyadh.jpg";
import website from "assets/images/website.jpg";
import simpleislam from "assets/images/simpleislam.jpg";
import userExp from "assets/images/userExp.jpg";

import {
  staggerText,
  staggerReveal,
  fadeInUp,
  handleHover,
  handleHoverExit,
  handleCityReturn,
  handleCity,
  staggerRevealClose,
} from "./Animations";
import { Typography } from "@material-ui/core";

const Menu = ({ state }) => {
  let menuLayer = useRef(null);
  let reveal1 = useRef(null);
  let reveal11 = useRef(null);
  let reveal2 = useRef(null);
  let cityBackground = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let info = useRef(null);

  const cities = [
    {
      name: "Mumbai, India",
      image: mumbai,
      // "https://lp-cms-production.imgix.net/image_browser/Mumbai_nightlife_S.jpg",
    },
    {
      name: "Riyadh, Saudi Arabia",
      image: riyadh,
      // "https://images.unsplash.com/photo-1506795213373-430e921fe2ed?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
      // "https://upload.wikimedia.org/wikipedia/commons/2/20/Riyadh_Skyline.jpg",
    },
  ];

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.
      staggerRevealClose(reveal2, reveal11, reveal1);
      // Set menu to display none
      gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
      //Allow menu to have height of 100%
      gsap.to([reveal1, reveal11, reveal2], {
        duration: 0,
        opacity: 1,
        height: "100%",
      });
      staggerReveal(reveal1, reveal11, reveal2);
      fadeInUp(info);
      // staggerText(line1, line2, line3);
    }
  }, [state]);

  return (
    <div ref={(el) => (menuLayer = el)} className="hamburger-menu">
      <div
        ref={(el) => (reveal11 = el)}
        className="menu-secondary-background-color2"
      ></div>
      <div
        ref={(el) => (reveal1 = el)}
        className="menu-secondary-background-color"
      ></div>
      <div ref={(el) => (reveal2 = el)} className="menu-layer">
        <div
          ref={(el) => (cityBackground = el)}
          className="menu-city-background"
        ></div>
        <div className="container">
          <div className="wrapper">
            <div className="menu-links">
              <nav>
                <ul>
                  <li>
                    <h3
                      onMouseEnter={() => handleCity(about, cityBackground)}
                      onMouseOut={() => handleCityReturn(cityBackground)}
                      ref={(el) => (line1 = el)}
                    >
                      About Me
                    </h3>
                  </li>
                  <li>
                    <p
                      ref={(el) => (info = el)}
                      style={{ textAlign: "center" }}
                    >
                      I am a Software Engineer from
                      <span
                        style={{ color: "#7100e2" }}
                        key={"Mumbai, India"}
                        onMouseEnter={() => handleCity(mumbai, cityBackground)}
                        onMouseOut={() => handleCityReturn(cityBackground)}
                      >
                        {" Mumbai, India"}
                      </span>
                      , currently living in
                      <span
                        style={{ color: "#7100e2" }}
                        key={"Riyadh, Saudi Arabia"}
                        onMouseEnter={() => handleCity(riyadh, cityBackground)}
                        onMouseOut={() => handleCityReturn(cityBackground)}
                      >
                        {" Riyadh, Saudi Arabia"}
                      </span>
                      <br />
                      <br />
                      My objective in life is to make a difference with what I
                      do and to have a strong impact with the tasks on people's
                      life.
                      <br />
                      <br />
                      Currently I am helping start-ups, small businesses, and
                      agencies achieve high quality
                      <span
                        style={{ color: "#7100e2" }}
                        key={" Websites"}
                        onMouseEnter={() => handleCity(website, cityBackground)}
                        onMouseOut={() => handleCityReturn(cityBackground)}
                      >
                        {" Websites,"}
                      </span>
                      <span
                        style={{ color: "#7100e2" }}
                        key={" Apps "}
                        onMouseEnter={() =>
                          handleCity(simpleislam, cityBackground)
                        }
                        onMouseOut={() => handleCityReturn(cityBackground)}
                      >
                        {" Apps "}
                      </span>
                      and exceptional{" "}
                      <span
                        style={{ color: "#7100e2" }}
                        key={" User Experience "}
                        onMouseEnter={() => handleCity(userExp, cityBackground)}
                        onMouseOut={() => handleCityReturn(cityBackground)}
                      >
                        {" User Experience "}
                      </span>
                    </p>
                  </li>
                </ul>
              </nav>
              {/* <div ref={(el) => (info = el)} className="info">
                <h3>Our Promise</h3>
                <p>
                  The passage experienced a surge in popularity during the 1960s
                  when Letraset used it on their dry-transfer sheets, and again
                  during the 90s as desktop publishers bundled the text with
                  their software.
                </p>
              </div> */}
              {/* <div className="locations">
                {cities.map((el) => (
                  <span
                    key={el.name}
                    onMouseEnter={() => handleCity(el.image, cityBackground)}
                    onMouseOut={() => handleCityReturn(cityBackground)}
                  >
                    {el.name}
                  </span>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
