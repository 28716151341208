import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  // Button,
  // SwipeableDrawer,
  // Hidden,
  useMediaQuery,
} from "@material-ui/core";
import "./test.module.css";
import Aos from "aos";
import Header from "components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
// import mailsent from "../assets/mailsent.json";
import waiting from "../assets/waiting.json";
import message from "../assets/message.json";
import { db } from "config/fb";
import { doc, getDoc } from "firebase/firestore/lite";

const useStyles = makeStyles((theme) =>
  // console.log(theme) || {
  ({
    root: {
      height: "100vh",
      width: "100vw",
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "center",
      overflow: "hidden",
      backgroundImage: "linear-gradient(140deg,#25262a  30%, black 100%)",
      backgroundPosition: "fixed",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    nav: {
      position: "fixed",
      top: 40,
      right: 100,
      [theme.breakpoints.down("sm")]: {
        right: 20,
      },
    },
    heading: {
      fontFamily: "Qwigley",
      marginBottom: 50,
      transform: `rotate(-5deg)`,
      [theme.breakpoints.down("sm")]: {
        fontSize: 65,
        paddingTop: 50,
      },
    },
    hero: {
      minHeight: `calc(90vh)`,
      width: "100vw",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "center",
      // paddingLeft: 50,
      // backgroundColor: "#25262a",
      // backgroundImage: "linear-gradient(140deg,#25262a  30%, black 100%)",

      // background: `url(${bg}) no-repeat center center fixed`,

      // [theme.breakpoints.down("sm")]: {
      //   flexDirection: "column",
      //   justifyContent: "center",
      // },
    },
    heroContent: {
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 100,
        marginBottom: 100,
      },
    },
    logo: {
      marginTop: -100,
      paddingBottom: 100,
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        paddingTop: 0,
        paddingBottom: 50,
        marginLeft: -40,
        textAlign: "center",
      },
    },

    maskText: {
      // fontFamily: "Qwigley",
      paddingBottom: 20,
      fontWeight: "bold",
      maxWidth: 600,
      color: "transparent",
      WebkitBackgroundClip: "text",
      backgroundSize: `300px auto`,
      background:
        "url(https://res.cloudinary.com/dgv2ytq4r/image/upload/v1584483079/bubble-white_ocbnqz.png) repeat",
      backgroundClip: "text",
      animation: `$upward 12s infinite ${theme.transitions.easing.sharp}`,
    },
    "@keyframes upward": {
      "0%": {
        backgroundPosition: "0 0",
      },
      "100%": {
        backgroundPosition: " 0 -300px",
      },
    },
    floating: {
      animation: `$float 3s infinite ${theme.transitions.easing.sharp}`,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 50,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    "@keyframes float": {
      "0%": { transform: "translate(0,  0px)" },
      "50%": { transform: "translate(0, 15px)" },
      "100%": { transform: "translate(0, 0px)" },
    },
    ctaBtn: {
      marginTop: 20,
      borderRadius: 25,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    drawer: {
      width: "60vw",
      height: "100%",
      backgroundColor: "#25262a",
    },
  })
);

function Message(props) {
  const classes = useStyles();

  const [request, setRequest] = useState(null);

  const mob = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  let params = useParams();
  const { state } = useLocation();

  const fromMessage = state?.fromMessage ? state.fromMessage : false;

  useEffect(() => {
    Aos.refreshHard();
    getRequest();
  }, []);

  const getRequest = async () => {
    if (!fromMessage) {
      const docRef = doc(db, "emails", params?.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setRequest({ ...docSnap.data(), id: docSnap.id });
      } else {
        navigate("/404");
      }
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Header />
        <div className={classes.hero}>
          {fromMessage && (
            <div className={classes.heroContent}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  color: "white",
                }}
              >
                Status : Pending
              </Typography>
              <Lottie
                animationData={message}
                style={{ height: 400, marginTop: 50 }}
                loop={true}
              />

              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 40,
                  color: "white",
                  marginTop: 100,
                }}
              >
                Message Sent
              </Typography>

              <Typography
                style={{ textAlign: "center", fontSize: 20, color: "white" }}
              >
                Thank you for reaching out, I will get back to you as soon as I
                can.
              </Typography>
              <Typography
                style={{ textAlign: "center", fontSize: 20, color: "white" }}
              >
                This is a unique URL for you request
              </Typography>
            </div>
          )}
          {request ? (
            <div className={classes.heroContent}>
              <Lottie
                animationData={waiting}
                style={{ height: 600, marginTop: -200 }}
                loop={true}
              />
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  color: "white",
                }}
              >
                Hi! {request?.name}
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 40,
                  color: "white",
                }}
              >
                Status : {request?.status}
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  color: "white",
                  maxWidth: 650,
                }}
              >
                {request?.reply
                  ? request.reply
                  : "Thank you for reaching out, I have received your message and I will get back to you as soon as I check your message."}
              </Typography>
            </div>
          ) : !fromMessage && !request ? (
            <div className={classes.heroContent}>
              <Lottie
                animationData={waiting}
                style={{ height: 600, marginTop: -200 }}
                loop={true}
              />
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 40,
                  color: "white",
                }}
              >
                Fetching your request...
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Message;
