import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Tooltip } from "@material-ui/core";
import apollo from "../assets/images/Tech/apollo.png";
import aws from "../assets/images/Tech/aws.png";
import aws2 from "../assets/images/Tech/aws2.png";
import bitbucket from "../assets/images/Tech/bitbucket.png";
import bootstrap from "../assets/images/Tech/bootstrap.png";
import css from "../assets/images/Tech/css.png";
import expo from "../assets/images/Tech/expo.png";
import firebase from "../assets/images/Tech/firebase.png";
import github from "../assets/images/Tech/github.svg";
import graphql from "../assets/images/Tech/graphql.png";
import html from "../assets/images/Tech/html.png";
import nextjs from "../assets/images/Tech/nextjs.png";
import node from "../assets/images/Tech/node.png";
import npm from "../assets/images/Tech/npm.png";
import react from "../assets/images/Tech/react.png";
import redux from "../assets/images/Tech/redux.png";
import rn from "../assets/images/Tech/rn.png";
import trello from "../assets/images/Tech/trello.png";
import vscode from "../assets/images/Tech/vscode.png";
import webpack from "../assets/images/Tech/webpack.png";
import bq from "../assets/images/Tech/google_bq.png";
import bg2 from "../assets/images/bg2_10.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) =>
  // console.log(theme) || {
  ({
    heading: {
      fontFamily: "Qwigley",
      marginBottom: 50,
      transform: `rotate(-5deg)`,
      [theme.breakpoints.down("sm")]: {
        fontSize: 65,
        paddingTop: 50,
      },
    },
    icon: {
      height: 40,
      margin: 8,
      marginLeft: 0,
      [theme.breakpoints.down("sm")]: {
        height: 40,
        margin: 15,
        marginLeft: 0,
      },
    },
    techRoot: {
      width: 400,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: 300,
      },
    },
  })
);

function TechStack() {
  const classes = useStyles();
  const mob = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div className={classes.techRoot}>
      <Grid container direction="row" style={{ maxWidth: 400 }}>
        <Grid item>
          <Tooltip title="JavaScript / NodeJs">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1000"}
              alt={"Tech Stack"}
              src={node}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="React / ReactNative">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1100"}
              alt={"Tech Stack"}
              src={react}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>

        {/* <Grid item>
          <img
            alt={"Tech Stack"}
            src={rn}
            className={classes.icon}
            style={{ backgroundColor: "#29dbff", borderRadius: 5 }}
          />
        </Grid> */}
        <Grid item>
          <Tooltip title="Expo / Hybrid Mobile App">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1200"}
              alt={"Tech Stack"}
              src={expo}
              className={classes.icon}
              style={{ borderRadius: "50%" }}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Redux">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1300"}
              alt={"Tech Stack"}
              src={redux}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>
        {/* <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={html} className={classes.icon} />
        </Grid>
        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={css} className={classes.icon} />
        </Grid> */}
        <Grid item>
          <Tooltip title="Firebase Cloud Services">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1400"}
              alt={"Tech Stack"}
              src={firebase}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Amazon Web Services">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1500"}
              alt={"Tech Stack"}
              src={aws2}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="GraphQl">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1600"}
              alt={"Tech Stack"}
              src={graphql}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Google Big Query">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1700"}
              alt={"Tech Stack"}
              src={bq}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Github">
            <img
              data-aos="flip-down"
              data-aos-once="true"
              data-aos-delay={mob ? 0 : "1800"}
              alt={"Tech Stack"}
              src={github}
              className={classes.icon}
            />
          </Tooltip>
        </Grid>
        {/* <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={apollo} className={classes.icon} />
        </Grid>
        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={webpack} className={classes.icon} />
        </Grid>
        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={bootstrap} className={classes.icon} />
        </Grid>
      
        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={bitbucket} className={classes.icon} />
        </Grid>
        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={nextjs} className={classes.icon} />
        </Grid>

        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={npm} className={classes.icon} />
        </Grid>

        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={trello} className={classes.icon} />
        </Grid>
        <Grid item>
          <img data-aos="flip-down" data-aos-once="true" alt={"Tech Stack"} src={vscode} className={classes.icon} />
        </Grid> */}
      </Grid>
    </div>
  );
}

export default TechStack;
