import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import { db } from "config/fb";
import { collection, addDoc } from "firebase/firestore/lite";
import { useNavigate } from "react-router-dom";

const theme = {
  background: "#25262a",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#7100e2",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#7100e2",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const Review = (props) => {
  const { name, tech, project, contact } = props.steps;
  return (
    <div style={{ width: "100%" }}>
      <h3>Summary</h3>
      <table>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{name.value}</td>
          </tr>
          <tr>
            <td>Technology</td>
            <td>{tech.value}</td>
          </tr>
          <tr>
            <td>Project</td>
            <td>{project.value}</td>
          </tr>
          <tr>
            <td>Contact</td>
            <td>{contact.value}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ChatBotBubble = ({ toggleBot, setToggleBot }) => {
  let navigate = useNavigate();
  const steps = [
    {
      id: "0",
      message: "Greetings!",
      trigger: "1",
    },
    {
      id: "1",
      message: "What is your name?",
      trigger: "name",
      hideInput: true,
    },
    {
      id: "name",
      user: true,
      placeholder: "eg. John",
      trigger: "3",
    },
    {
      id: "3",
      message: "Hi {previousValue}! How can I help you?",
      trigger: "tech",
    },
    {
      id: "tech",
      options: [
        { value: "App", label: "Mobile app", trigger: "5" },
        { value: "Web App", label: "Web Application", trigger: "5" },
        { value: "Website", label: "Website", trigger: "5" },
        { value: "Other", label: "Others", trigger: "5" },
      ],
    },
    {
      id: "5",
      message: "Describe your project in few words?",
      trigger: "project",
    },
    {
      id: "project",
      user: true,
      trigger: "6",
      //   validator: (value) => {
      //     if (isNaN(value)) {
      //       return "value must be a number";
      //     } else if (value < 0) {
      //       return "value must be positive";
      //     } else if (value > 120) {
      //       return `${value}? Come on!`;
      //     }

      //     return true;
      //   },
    },
    {
      id: "6",
      message: "How can I contact you?",
      trigger: "contact",
    },
    {
      id: "contact",
      user: true,
      placeholder: "Email or Mobile",
      trigger: "7",
    },
    {
      id: "7",
      message: "Great!",
      trigger: "review",
    },
    {
      id: "review",
      component: <Review />,
      asMessage: true,
      trigger: "update",
    },
    {
      id: "update",
      message: "Would you like to update some field?",
      trigger: "update-question",
    },
    {
      id: "update-question",
      options: [
        { value: "yes", label: "Yes", trigger: "update-yes" },
        { value: "no", label: "No", trigger: "end-message" },
      ],
    },
    {
      id: "update-yes",
      message: "What field would you like to update?",
      trigger: "update-fields",
    },
    {
      id: "update-fields",
      options: [
        { value: "name", label: "Name", trigger: "update-name" },
        {
          value: "tech",
          label: "Technology",
          trigger: "update-tech",
        },
        { value: "project", label: "Project", trigger: "update-project" },
      ],
    },
    {
      id: "update-name",
      update: "name",
      trigger: "7",
    },
    {
      id: "update-tech",
      update: "tech",
      trigger: "7",
    },
    {
      id: "update-project",
      update: "project",
      trigger: "7",
    },
    {
      id: "end-message",
      message: "Thanks! Your data was submitted successfully!",
      end: true,
    },
  ];

  const handleEnd = ({ steps, values }) => {
    // console.log(steps);
    // console.log(values);
    addDoc(collection(db, "emails"), {
      name: values[0],
      tech: values[1],
      projectDesc: values[2],
      contact: values[3],
      status: "Pending",
      reply: "",
      action: "",
      createdAt: new Date(),
      updatedAt: new Date(),
    })
      .then((i) => {
        navigate("/message/" + i.id, { state: { fromMessage: true } });
      })
      .catch((e) => {
        console.log(e);
      });
    // firebase.firestore().collection("emails").doc().set({
    //   name: values[0],
    //   tech: values[1],
    //   projectDesc: values[2],
    //   contact: values[3],
    //   createdAt: new Date(),
    // });
  };

  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        floating
        // hideBotAvatar
        headerTitle="Get In Touch"
        handleEnd={handleEnd}
        opened={toggleBot}
        toggleFloating={() => {
          setToggleBot(!toggleBot);
        }}
        botDelay={500}
        userDelay={100}
        steps={steps}
      />
    </ThemeProvider>
  );
};

export default ChatBotBubble;
