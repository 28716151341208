import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  // SwipeableDrawer,
  Hidden,
  useMediaQuery,
} from "@material-ui/core";
import "./test.module.css";
import SmallTechStack from "../components/SmallTechStack";
// import Services from "../components/Services";
// import Projects from "../components/Projects";
// import HeroSection from "../components/HeroSection";
import heroImage from "assets/Thr2.png";
import Aos from "aos";
import Header from "components/Header";
import ChatBotBubble from "components/ChatBot";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  // console.log(theme) || {
  ({
    root: {
      height: "100vh",
      width: "100vw",
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "center",
      overflow: "hidden",
      backgroundImage: "linear-gradient(140deg,#25262a  30%, black 100%)",
      backgroundPosition: "fixed",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    nav: {
      position: "fixed",
      top: 40,
      right: 100,
      [theme.breakpoints.down("sm")]: {
        right: 20,
      },
    },
    heading: {
      fontFamily: "Qwigley",
      marginBottom: 50,
      transform: `rotate(-5deg)`,
      [theme.breakpoints.down("sm")]: {
        fontSize: 65,
        paddingTop: 50,
      },
    },
    hero: {
      minHeight: `calc(90vh)`,
      width: "100vw",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      // paddingLeft: 50,
      // backgroundColor: "#25262a",
      // backgroundImage: "linear-gradient(140deg,#25262a  30%, black 100%)",

      // background: `url(${bg}) no-repeat center center fixed`,

      [theme.breakpoints.down("sm")]: {
        paddingLeft: "5%",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    heroContent: {
      paddingLeft: 50,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 100,
        marginBottom: 100,
      },
    },
    logo: {
      marginTop: -100,
      paddingBottom: 100,
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        paddingTop: 0,
        paddingBottom: 50,
        marginLeft: -40,
        textAlign: "center",
      },
    },

    maskText: {
      // fontFamily: "Qwigley",
      paddingBottom: 20,
      fontWeight: "bold",
      maxWidth: 600,
      color: "transparent",
      WebkitBackgroundClip: "text",
      backgroundSize: `300px auto`,
      background:
        "url(https://res.cloudinary.com/dgv2ytq4r/image/upload/v1584483079/bubble-white_ocbnqz.png) repeat",
      backgroundClip: "text",
      animation: `$upward 12s infinite ${theme.transitions.easing.sharp}`,
    },
    "@keyframes upward": {
      "0%": {
        backgroundPosition: "0 0",
      },
      "100%": {
        backgroundPosition: " 0 -300px",
      },
    },
    floating: {
      animation: `$float 3s infinite ${theme.transitions.easing.sharp}`,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 50,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    "@keyframes float": {
      "0%": { transform: "translate(0,  0px)" },
      "50%": { transform: "translate(0, 15px)" },
      "100%": { transform: "translate(0, 0px)" },
    },
    ctaBtn: {
      marginTop: 20,
      borderRadius: 25,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    drawer: {
      width: "60vw",
      height: "100%",
      backgroundColor: "#25262a",
    },
  })
);

function Home() {
  const classes = useStyles();
  const mob = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    Aos.refreshHard();
  }, []);

  const [contact, setContact] = useState(false);
  const [toggleBot, setToggleBot] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setContact(open);
  };
  return (
    <>
      <div className={classes.root}>
        <Header />
        <ChatBotBubble toggleBot={toggleBot} setToggleBot={setToggleBot} />

        {/* <div className={classes.nav}>
          <Fab color="primary" aria-label="add" className={classes.fab}>
            <GrainIcon />
          </Fab>
        </div> */}
        <div className={classes.hero}>
          <div className={classes.heroContent}>
            <Typography
              data-aos="fade"
              id="title"
              variant="h6"
              className={classes.logo}
            >
              {`< THRWorks / >`}
            </Typography>

            <Hidden mdUp>
              <div
                className={classes.floating}
                data-aos="zoom-out"
                // data-aos-duration="1200"
                data-aos-easing="ease-in-out-back"
              >
                <img
                  data-aos="zoom-out"
                  // data-aos-anchor="#title"
                  data-aos-delay="50"
                  data-aos-easing="ease-in-out-back"
                  data-aos-duration="1200"
                  src={heroImage}
                  style={{ width: 350, marginLeft: -40 }}
                />
              </div>
            </Hidden>

            <Typography
              data-aos="zoom-out-up"
              data-aos-delay="300"
              variant="h3"
              data-aos-anchor="#title"
              className={classes.maskText}
            >
              Mohammed Taher
            </Typography>

            <Typography
              data-aos="zoom-out-up"
              data-aos-delay="500"
              data-aos-anchor="#title"
              variant="h4"
              className={classes.maskText}
            >
              Mobile & Web Application Developer
            </Typography>

            <Typography
              data-aos="zoom-out-up"
              data-aos-delay="800"
              data-aos-anchor="#title"
              variant="h6"
              className={classes.maskText}
            >
              Riyadh, Saudi Arabia
            </Typography>
            <SmallTechStack />
            {/* <a
              target="_blank"
              rel="noopener"
              rel="nofollow"
              href="mailto:thrworks@gmail.com?subject=Getting In Touch&body=Your Message"
              style={{ textDecoration: "none" }}
            > */}
            <Hidden smDown>
              <Button
                variant="contained"
                size="large"
                color="primary"
                data-aos="zoom-down"
                data-aos-once="true"
                data-aos-delay={mob ? 0 : "2000"}
                className={classes.ctaBtn}
                onClick={() => setToggleBot(true)}
                // onClick={() =>
                //   navigate("/message/sadh", { state: { fromMessage: true } })
                // }
              >
                Get In Touch
              </Button>
            </Hidden>
            {/* </a> */}

            {/* <SwipeableDrawer
              anchor={"right"}
              open={contact}
              onClose={toggleDrawer("right", false)}
              onOpen={toggleDrawer("right", true)}
            >
              <div className={classes.drawer}>Hello</div>
            </SwipeableDrawer> */}
          </div>
          <Hidden smDown>
            <div
              className={classes.floating}
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out-back"
            >
              <img
                data-aos="fade"
                // data-aos-anchor="#title"
                data-aos-delay="50"
                data-aos-easing="ease-in-out-back"
                data-aos-duration="1200"
                src={heroImage}
                style={{ width: 700, marginLeft: -100 }}
              />
            </div>
          </Hidden>
        </div>
        {/* <div className={classes.hero}>
          <Services />
        </div> */}
        {/* <div className={classes.hero}>
          <HeroSection />
        </div> */}
        {/* <div className={classes.hero}>
          <Projects />
        </div> */}
      </div>
    </>
  );
}

export default Home;
